<template>
<b-container>
  <b-row>
    <b-col sm="auto" md="10" lg="8" xl="6">
      <h1>Nya anmälningar</h1>
      <b-form inline class="mb-3">
        <b-form-select v-model="selectedPeriod" :options="periods" />
        <b-button class="ml-2" variant="primary"
                  :disabled="fetchingProspects"
                  @click="fetchProspects">Visa anmälningar</b-button>
      </b-form>

      <div v-if="fetchingProspects">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <b-table v-else hover fixed :items="groupOverview" :fields="groupFields" selectable @row-selected="onGroupSelected" :sort-compare="sortGroups" sortBy="name">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Vald</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Inte vald</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="filteredProspects.length > 0">
      <b-col>
      <h1>Anmälningar</h1>
      <b-table striped hover :items="filteredProspects" :fields="fields"></b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { db } from '@/firebaseConfig.js'
  import { collection, getDocs, query, where } from 'firebase/firestore'

  export default {
    data() {
      return {
        prospects: [],
        groupData: [
          {key: 'mon-nyb', name: 'Mon nybörjare' },
          {key: 'mon-gul', name: 'Mon gul-vita' },
          {key: 'mon-orange', name: 'Mon orange-vita' },
          {key: 'mon-gron', name: 'Mon grön-vita' },
          {key: 'mon-bla', name: 'Mon blå-vita' },
          {key: 'mon-brun', name: 'Mon brun-vita' },
          {key: 'ung-nyb', name: 'Ungdomar nybörjare' },
          {key: 'ung-gul', name: 'Ungdomar fd. mon' },
          {key: 'ung-gul', name: 'Ungdomar gul' },
          {key: 'ung-orange', name: 'Ungdomar orange' },
          {key: 'ung-gron', name: 'Ungdomar grön' },
          {key: 'nyb-intresse', name: 'Vuxna nybörjare intresseanmälan' },
          {key: 'nyb', name: 'Vuxna nybörjare' },
          {key: 'nyb-forts', name: 'Vuxna nybörjare fortsättning' },
          {key: 'gul', name: 'Gul' },
          {key: 'orange', name: 'Orange' },
          {key: 'gron', name: 'Grön' },
          {key: 'bla', name: 'Blå' },
          {key: 'brun', name: 'Brun' },
          {key: '1dan', name: '1 dan' },
          {key: '2danplus', name: '2 till 8 dan' }
        ],
        groupFields: [
          {key: 'selected', label: 'Vald', thStyle: { width: '4em' }},
          {key: 'name', label: 'Grupp'},
          {key: 'count', label: 'Antal', thStyle: { width: '4em' }}
        ],
        fields: [
          {key: 'group',  sortable: true, label: 'Grupp'},
          {key: 'name', sortable: true, label: 'Namn', thStyle: { width: '15em' }},
          {key: 'guardianName',  sortable: true, label: 'Vårdnadshavare'},
          {key: 'email', label: 'Epost'},
          {key: 'phoneNumber', label: 'Telefon'},
          {key: 'comments',  sortable: true, label: 'Övrigt'},
          {key: 'created_at',  sortable: true, sortByFormatted: true, label: 'Inskickad', formatter: this.asDateTime},
        ],
        selectedGroups: [],
        periods: ['2021-ht', '2022-vt', '2022-ht', 'vt-2023', 'ht-2023', 'vt-2024', 'ht-2024'],
        selectedPeriod: 'ht-2024',
        fetchingProspects: false
      }
    },
    methods: {
      async fetchProspects() {
        this.fetchingProspects = true;
        const result = []
        const q = query(collection(db, 'prospects'), where('period', '==', this.selectedPeriod))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          result.push({ id: doc.id, ...doc.data() })
        })
        this.prospects = result
        this.fetchingProspects = false;
      },
      asDateTime(timestamp) {
        return timestamp.toDate().toJSON()
          .replace(/[TZ]/g, ' ')    // remove letter 'T' and 'Z'
          .replace(/\.\d{3}/, ' '); // remove milliseconds
      },

      onGroupSelected(groups) {
        this.selectedGroups = groups.map(g => g.group);
      },

      groupByKey(groupKey) {
        return this.groupData.find(g => g.key === groupKey)
      },

      groupReduce(allGroups, prospect) {
        var groupName = this.groupByKey(prospect.group).name
        let index = allGroups.findIndex(element => element.group === prospect.group)
        if (index === -1) {
          allGroups.push({
            group: prospect.group,
            name: groupName,
            count: 1})
        }
        else {
          allGroups[index].count++
        }
        return allGroups
      },

      sortGroups(rowA, rowB, key) {
        var a = 0
        var b = 0
        if (key === 'name') {
          a = this.groupData.findIndex(g => g.key === rowA.group)
          b = this.groupData.findIndex(g => g.key === rowB.group)
        }
        return a < b ? -1 : a > b ? 1 : 0;
      }
    },

    computed: {
      filteredProspects() {
        return this.prospects
          .filter(p => this.selectedGroups.includes(p.group))
          .map(p => {
            return { ...p,
                     name: p.firstName + ' ' + p.lastName,
                     guardianName: p.guardianFirstName + ' ' + p.guardianLastName
                   }
            })
      },

      /**
       * An array where each element is an object representing a group and its prospect count.
       * Example: [ {group: 'Group 1', count: 4}, {group: 'Group A', count: 3 } ]
       */
      groupOverview() {
        return this.prospects.reduce(this.groupReduce, [])
      }

    }
  }

</script>
